import MainBanner from "@components/fullcoveragewarranty/mainBanner";
import FullCoverageWarrantyWrapper from "@components/layouts/fullCoverageWarrantyWrapper";
import { getPageProps } from "@services/initial-calls";
import { GetServerSideProps } from "next";
import React, { ReactElement } from "react";
import Benefits from "@components/fullcoveragewarranty/benefits";
import WhatIsHomeWarranty from "@components/fullcoveragewarranty/whatIsHomeWarranty";
import WhatIsCarWarranty from "@components/fullcoveragewarranty/whatIsCarWarranty";
export default function Home(): ReactElement {
    return (
        <FullCoverageWarrantyWrapper>
            <MainBanner />
            <Benefits />
            <WhatIsHomeWarranty />
            <WhatIsCarWarranty />
        </FullCoverageWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
